import React, { useState, useEffect } from "react";
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, AccordionIcon, Link, Box, useColorMode } from "@chakra-ui/react";
import { Link as ReactLink, useLocation } from "react-router-dom";
import NavButton from "../NavButton/NavButton";
import { useTranslation } from "react-i18next";
import { getColorThemeSelector } from "../../../utils/funcs";

interface Props {
    page: PageConfig;
    showNav?: boolean;
    setShowNav?(show: boolean): void;
    img?: string;
}

const NavAccordion: React.FC<Props> = ({ page, showNav, setShowNav, img }) => {
    const location = useLocation();
    const currentPage = location.pathname.substring(1);
    const { t } = useTranslation();
    const [index, setIndex] = useState([-1]);

    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const color = colorTheme("#8F85A7", "#A89EC0");
    const colorHover = colorTheme("#0C0C0C", "#D6D0E5");
    const backgroundColor = colorTheme("#F5F7FF", "#332B52");

    useEffect(() => {
        if (!showNav) {
            setIndex([-1]);
        }
    }, [showNav, setIndex, currentPage, page]);

    useEffect(() => {
        if (showNav && currentPage.split("/")[0] === page.name) {
            setTimeout(() => {
                setIndex([0]);
            }, 300);
        }
    }, [showNav, setIndex, currentPage, page]);

    const onClick = () => {
        if (!showNav) {
            setTimeout(() => {
                setIndex([0]);
            }, 300);
        } else {
            setIndex([Number(!index[0])]);
        }
        setShowNav && setShowNav(true);
    };

    return (
        <Accordion
            allowToggle={true}
            //defaultIndex={currentPage.split('/')[0] === page.name ? 0 : -1}
            index={index}
        >
            <AccordionItem border="none" isFocusable={false}>
                <AccordionButton padding="0" _hover={{ bgColor: "unset" }} onClick={() => onClick()}>
                    <NavButton disableLink={true} page={page.name} isSelected={false} width="100%" showNav={showNav} img={img}>
                        <AccordionIcon />
                    </NavButton>
                </AccordionButton>
                <AccordionPanel p="0 0 0 61px" backgroundColor={backgroundColor}>
                    {showNav &&
                        page.children.map((child) =>
                            child.address?.startsWith("http") ? (
                                <Link
                                    key={child.name + child.address}
                                    href={child.address}
                                    rel="noreferrer"
                                    fontFamily="Montserrat-Medium"
                                    display="block"
                                    fontSize="13px"
                                    pt="13px"
                                    pb="11px"
                                    pl="27px"
                                    mr="34px"
                                    color={color}
                                    fontWeight="bold"
                                    target="_blank"
                                    _hover={{
                                        color: colorHover,
                                    }}
                                >
                                    {showNav ? child.name : child.name.charAt(0)}
                                </Link>
                            ) : (
                                <ReactLink to={child.address as string} key={child.name + child.address}>
                                    <Box
                                        display="block"
                                        fontFamily="Montserrat-Medium"
                                        fontSize="13px"
                                        pt="13px"
                                        mr="34px"
                                        pl="27px"
                                        pb="11px"
                                        color={"/" + currentPage === child.address ? colorHover : color}
                                        fontWeight="bold"
                                        target="_blank"
                                        _hover={{
                                            color: colorHover,
                                        }}
                                    >
                                        {showNav ? t(child.name) : t(child.name).charAt(0)}
                                    </Box>
                                </ReactLink>
                            )
                        )}
                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    );
};

export default NavAccordion;
