import React, { useState } from "react";
import { Flex, Box, useColorMode, Stack } from "@chakra-ui/react";
import Navbar from "../Navbar/Navbar";
import MediaList from "../MediaList/MediaList";
import CopyrightInfo from "../CopyrightInfo/CopyrightInfo";
import "./Sidebar.css";
import ColorModeSelect from "../ColorModeSelect/ColorModeSelect";
import ToggleIcon from "../ToggleIcon/ToggleIcon";
import { getColorThemeSelector } from "../../../utils/funcs";
import useIsMobile from "../../../iZUMi-UI-toolkit/src/hooks/useIsMobile";
//import LanguageSelect from '../LanguageSelect/LanguageSelect';

const Sidebar: React.FC = () => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const bgColor = colorTheme("#ffffff", "#1C1D24");
    const [showNav, setShowNav] = useState(false);

    return (
        <Flex
            //h="100%"
            h="fill-available" // 100%, fix bug to iPad
            w={{
                base: showNav ? "70%" : "0px",
                sm: showNav ? "248px" : "0px",
                lg: showNav ? "248px" : "89px",
            }}
            direction="column"
            boxShadow="0 0 15px 0 rgb(0 0 0 / 3%)"
            bgColor={bgColor}
            visibility={{ base: showNav ? "visible" : "hidden", lg: "visible" }}
            align="flex-start"
            flexShrink={0}
            transitionProperty="width"
            transitionDuration="0.3s"
            transitionTimingFunction="ease"
            transitionDelay={showNav ? "0s" : "0.2s"}
            //position={{ base: 'absolute', lg: 'relative' }}
            position="relative"
            zIndex={{ base: "8", lg: "8" }}
            pt={{ base: "33px", sm: "40px" }}
        >
            <ToggleIcon
                top={{ base: "40px", sm: "60px" }}
                right={showNav ? { base: "-13px", sm: "-18px" } : { base: "-22px", sm: "-18px" }}
                showNav={showNav}
                setShowNav={setShowNav}
                zIndex="8 !important"
                visibility="visible"
            />
            <Navbar setShowNav={setShowNav} showNav={showNav} />
            <Box flex={1} />
            {showNav ? (
                <Stack
                    w="100%"
                    mb={{ base: "160px", sm: "120px" }}
                    alignItems={{ base: "start", sm: "center" }}
                    px={{ base: "28px", sm: "0px" }}
                >
                    <Flex direction="row" w={{ base: "100%", sm: "184px" }} alignItems="center" mb={3} mx={{ base: "unset", sm: "unset" }}>
                        <ColorModeSelect showNav={showNav} />
                        {
                            //<LanguageSelect />
                        }
                        {/* <BuyiZiButton expand={showNav} ml="-16px !important" /> */}
                    </Flex>

                    <MediaList />
                    <CopyrightInfo />
                </Stack>
            ) : (
                <Flex direction="column" w="95%" align="center" mb="137px" mx="auto" px={4}>
                    <ColorModeSelect showNav={showNav} />
                    <Box mt="21px"></Box>
                    {
                        //  <LanguageSelect />
                    }
                    {/* <BuyiZiButton expand={showNav} /> */}
                </Flex>
            )}
        </Flex>
    );
};

export default Sidebar;
