import { useEffect, useMemo } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import AppLayout from "./components/layout/AppLayout";
import Sidebar from "./components/Sidebar/Sidebar/Sidebar";
import { i18nInitPromise } from "./i18n";
import LoadProvider from "./providers/LoadProvider";
import NotFound404 from "./views/NotFound404/NotFound404";
import useInterval from "ahooks/lib/useInterval";
import { useRematchDispatch } from "./hooks/useRematchDispatch";
import { RootDispatch } from "./state/store";
import { useWeb3WithDefault } from "./hooks/useWeb3WithDefault";
import { CHAIN_CONFIG } from "./config/bizConfig";
import Header from "./components/Header/Header";
import Dashboard from "./views/Dashboard/Dashboard";
import Pools from "./views/Pools/Pools";
import Tokens from "./views/Tokens/Tokens";
import Pool from "./views/Pools/PoolDetail";
import Token from "./views/Tokens/TokenDetail";
import Liquidity from "./views/Liquidity/Liquidity";
import LiquidityDetail from "./views/Liquidity/LiquidityDetail/LiquidityDetail";
import Transaction from "./views/Transaction/Transaction";
import useIsMobile from "./iZUMi-UI-toolkit/src/hooks/useIsMobile";

import "./App.css";

function App() {
    useAutoRedirectToHome();
    useAutoRefreshBlockNumber();
    const isMobile = useIsMobile();
    const promiseList = useMemo(() => [i18nInitPromise], []);
    const Center = (
        <Switch>
            <Route path="/dashboard" exact={true}>
                <Dashboard />
            </Route>
            <Route path="/pools" exact={true}>
                <Pools />
            </Route>
            <Route path="/pool" exact={true}>
                <Pool />
            </Route>
            <Route path="/tokens" exact={true}>
                <Tokens />
            </Route>
            <Route path="/token" exact={true}>
                <Token />
            </Route>
            <Route path="/transactions" exact={true}>
                <Transaction />
            </Route>
            <Route path="/liquidity" exact={true}>
                <Liquidity />
            </Route>
            <Route path="/liquidity-detail" exact={true}>
                <LiquidityDetail />
            </Route>
            <Route path="/more-info" exact={true}></Route>
            <Route path="*">
                <NotFound404 />
            </Route>
        </Switch>
    );

    return (
        <LoadProvider promiseList={promiseList}>
            {isMobile ? (
                // <AppLayout left={<Sidebar />} center={Center} header={<Header />} bottom={<TabBar config={config}></TabBar>} />
                <AppLayout left={<Sidebar />} center={Center} header={<Header />} />
            ) : (
                <AppLayout left={<Sidebar />} center={Center} header={<Header />} />
            )}
        </LoadProvider>
    );
}

export default App;

function useAutoRefreshBlockNumber() {
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { web3 } = useWeb3WithDefault();

    useInterval(() => {
        dispatch.block.fetchBlockNumber(web3);
    }, CHAIN_CONFIG.AUTO_REFRESH_BLOCK_NUMBER_INTERVAL);
}

// Redirect to /home if current pathname is /
function useAutoRedirectToHome() {
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
        if (location.pathname === "/") history.push("/dashboard");
    }, [history, location.pathname]);
}
