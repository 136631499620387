import { Box, BoxProps, useColorMode } from '@chakra-ui/react';
import React from 'react';
import { i_h4 } from '../../../style';
import { getColorThemeSelector } from '../../../utils/funcs';

export type ButtonProps = {
    text?: string | JSX.Element;
    variant?: 'primary' | 'outline' | 'purple' | 'tertiary' | 'primary2' | 'orange' | 'outlinePurple' | 'none';
    theme?: 'light' | 'dark';
    radiusType?: 'sm' | 'md' | 'lg';
    heightType?: 'sm' | 'md' | 'lg';
    widthType?: 'sm' | 'md' | 'lg';
    disabled?: boolean;
    fontClass?: any;
    selected?: boolean;
} & BoxProps;

const CustomButton: React.FC<ButtonProps> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { text, disabled, variant, selected, fontClass = i_h4, ...rest } = props;

    const themeColor = {
        outline: {
            base: colorTheme('#0166FF', '#EBE6F0'),
            hover: colorTheme('#D3E5FF', 'tertiary.600'),
        },
        outlinePurple: {
            base: colorTheme('tertiary.200', 'tertiary.'),
            hover: colorTheme('secondary.600', 'secondary.300'),
        },
        tertiary: {
            base: colorTheme('secondary.50', 'tertiary.600'),
            font: colorTheme('#8C7AA3', 'tertiary.100'),
            hover: colorTheme('secondary.200', 'tertiary.300'),
        },
        primary2: {
            base: colorTheme('#EBF1F8', '#42578D'),
            font: colorTheme('#687692', 'primary.50'),
            hover: colorTheme('primary.300', 'primary.200'),
        },
        orange: {
            base: colorTheme('#F8EBEB', '#9D6666'),
            font: colorTheme('#92686B', '#FFFFFF'),
            hover: colorTheme('#FFCDCD', '#A68787'),
        },
        none: {
            focus: colorTheme('tertiary.700', 'tertiary.200'),
        },
        disable: {
            base: colorTheme('#E0DEE7', '#3B2F50'),
            font: colorTheme('#F9F9F9', '#6E6878'),
        },
    };

    const customStyle = () => {
        switch (variant) {
            case 'primary':
                return {
                    border: 'none',
                    borderRadius: '3px',
                    background: '#0166FF',
                    color: 'secondary.50',
                    height: '60px',
                    width: '180px',
                    _hover: {
                        background: '#739FE3',
                    },
                    _focus: {
                        background: '#124899',
                    },
                };
            case 'outline':
                return {
                    borderWidth: '3px',
                    borderRadius: '3px',
                    borderStyle: 'solid',
                    borderColor: themeColor.outline.base,
                    backgroundColor: 'transparent',
                    color: themeColor.outline.base,
                    height: '60px',
                    width: '180px',
                    _hover: {
                        background: themeColor.outline.hover,
                    },
                    _focus: {
                        border: '3px solid #0166FF',
                    },
                };
            case 'outlinePurple':
                return {
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: selected ? themeColor.outlinePurple.hover : themeColor.outlinePurple.base,
                    backgroundColor: 'transparent',
                    color: themeColor.outline.base,
                    borderRadius: '6px',
                    height: '60px',
                    width: '180px',
                    _hover: {
                        borderColor: themeColor.outlinePurple.hover,
                    },
                    _focus: {
                        borderColor: themeColor.outlinePurple.hover,
                    },
                };
            case 'purple':
                return {
                    borderWidth: '2px',
                    borderStyle: 'solid',
                    borderColor: '#5542F6',
                    borderRadius: '3px',
                    background: '#5542F6',
                    color: '#ffffff',
                    height: '70px',
                    width: '300px',
                    _hover: {
                        background: '#A884FF',
                    },
                    _focus: {
                        background: '#4D19CB',
                    },
                };
            case 'tertiary': //approve like
                return {
                    borderStyle: 'solid',
                    borderRadius: '3px',
                    textAlign: 'center' as unknown as any,
                    backgroundColor: themeColor.tertiary.base,
                    color: themeColor.tertiary.font,
                    _hover: {
                        background: themeColor.tertiary.hover,
                    },
                };

            case 'primary2': //harvest like
                return {
                    borderStyle: 'solid',
                    borderRadius: '3px',
                    textAlign: 'center' as unknown as any,
                    backgroundColor: themeColor.primary2.base,
                    color: themeColor.primary2.font,
                    _hover: {
                        background: themeColor.primary2.hover,
                    },
                    _focus: {
                        undefined,
                    },
                };

            case 'orange': // withdraw like
                return {
                    borderStyle: 'solid',
                    borderRadius: '3px',
                    textAlign: 'center' as unknown as any,
                    backgroundColor: themeColor.orange.base,
                    color: themeColor.orange.font,
                    _hover: {
                        background: themeColor.orange.hover,
                    },
                    _focus: {
                        undefined,
                    },
                };
            case 'none':
                return {
                    color: selected ? themeColor.none.focus : colorTheme('tertiary.300', 'tertiary.500'),
                };

            default:
                return {};
        }
    };
    return (
        <Box
            as="button"
            transition="all 0.3s ease"
            borderRadius="2px"
            className={fontClass}
            {...customStyle()}
            disabled={disabled}
            _disabled={{
                background: themeColor.disable.base,
                color: themeColor.disable.font,
                cursor: 'not-allowed',
            }}
            {...rest}
        >
            {text}
        </Box>
    );
};

export default CustomButton;
