export enum ChainId {
    VirtualAll = 0,
    EthereumMainnet = 1,
    Rinkeby = 4,
    Optimism = 10,
    BSC = 56,
    Aurora = 1313161554,
    Gatechain = 86,
    BSCTestnet = 97,
    AuroraTestnet = 1313161555,
    Heco = 128,
    Matic = 137,
    Fantom = 250,
    Izumi = 1337,
    Arbitrum = 42161,
    MaticTestnet = 80001,
    Harmony = 1666600000,
    HarmonyTestnet = 1666700000,
    ETC = 61,
    Cronos = 25,
    ConfluxESpace = 1030,
    Meter = 82,
    ZkSyncEra = 324,
    Ontology = 58,
    Ultron = 1231,
    Mantle = 5000,
    Linea = 59144,
    Base = 8453,
    Loot = 5151706,
    Kroma = 255,

    ZkSyncAlphaTest = 280,
    MantleTest = 5001,
    ScrollTestL2 = 534353,
    IcplazaTest = 9000,
    Icplaza = 142857,
    BedrockRolluxTestL2 = 57000,
    LineaTest = 59140,
    OpsideTest = 51178,
    OpsideTestRollux = 12008,
    OpBNBTest = 5611,
}

export enum TokenSymbol {
    USDC = 'USDC',
    // ACY = 'ACY',
    // ETH = 'ETH',
    // WETH = 'WETH',
    USDT = 'USDT',
    // DAI = 'DAI',
    // BIT = 'BIT',
    IZI = 'iZi',
    // YIN = 'YIN',
    // DEVT = 'DEVT',
    // DDAO = 'DDAO',
    // BNB = 'BNB',
    // WBNB = 'WBNB',
    // IUSD = 'iUSD',
    // APEX = 'APEX',
    // BUSD = 'BUSD',
    // ESAPEX = 'esAPEX',
}

export enum ListSortByEnum {
    Default = 'Default',
    TimeAsc = 'Time Asc',
    TimeDesc = 'Time Desc',
    Liquidity = 'Liquidity',
}

declare global {
    type SupportedChainId = ChainId;
    type SupportedTokenSymbol = TokenSymbol;
}

export const DEFAULT_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
