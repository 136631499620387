import { BoxProps, VStack, Text, useColorMode, HStack, Divider, Flex, Image, Stack } from '@chakra-ui/react';
import CustomButton from '../../../components/Buttons/CustomButton/CustomButton';
import Card from '../../../components/Card/Card';
import { ChainId, TokenSymbol } from '../../../types/mod';
import { getColorThemeSelector } from '../../../utils/funcs';
import { FeeRate } from '../../components/FeeRate';
import { TokenIcons } from '../../components/TokenIcons';
import { i_text_copy, i_h4, i_h2, i_text_d, i_text_copy_bold } from '../../../style';
import { useEffect, useState } from 'react';
import { FavButton } from '../../components/FavButton';
import { ResponseIziSwapLiquidityRecord } from '../../../net/iZUMi-endpoints/src/restful/izumiSwapLiquidity';
import { useSelector } from 'react-redux';
import { RootDispatch, RootState } from '../../../state/store';
import { favInfoKey } from '../../../state/models/favorite/favorite';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { useSwapPriceRange } from '../../components/TokenSwapPriceRange';
import { formatNumber } from '../../../utils/tokenMath';
import {
    getGenericIziSwapMetaRecord,
    MetaRecordTypeEnum,
    ResponseIziPoolRecord,
} from '../../../net/iZUMi-endpoints/src/restful/izumiSwapBase';
import { TokenInfoFormatted } from 'iziswap-sdk/lib/base/types';
import { isNumeric } from '../../../utils/valid';
import useIsMobile from '../../../hooks/useIsMobile';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import { i_text_Bold } from '../../../iZUMi-UI-toolkit/src/style';

export interface LiquidityDetailInfo {
    tokenX: TokenInfoFormatted;
    tokenY: TokenInfoFormatted;
    liquidityAmount: string;
    amountXDecimal: number;
    amountYDecimal: number;
    feeXDecimal: number;
    feeYDecimal: number;
    worthAmountX: number;
    worthAmountY: number;
    worthAmountXRate: number;
    worthFeeX: number;
    worthFeeY: number;
}

type InfoBlockProps = { data: Partial<ResponseIziSwapLiquidityRecord>; liquidityDetailInfo: LiquidityDetailInfo } & BoxProps;

export const LiquidityInfoBlock: React.FC<InfoBlockProps> = (props) => {
    const { data, liquidityDetailInfo, ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const isMobile = useIsMobile();
    const chainId = data.chainId ?? ChainId.BSC;

    const { favorite } = useSelector((state: RootState) => state);
    const dispatch = useRematchDispatch((dispatch: RootDispatch) => dispatch);

    const [poolInfo, setPoolInfo] = useState<Partial<ResponseIziPoolRecord>>({});

    useEffect(() => {
        getGenericIziSwapMetaRecord<ResponseIziPoolRecord[]>({
            chain_id: data.chainId,
            type: MetaRecordTypeEnum.IZI_SWAP_POOL,
            contract_addr: data.address,
        }).then((r) => {
            const poolResult = r.data.is_success ? r.data.data : [];
            setPoolInfo(poolResult?.[0] ?? {});
        });
    }, [data.address, data.chainId]);

    const entry = {
        nft_id: data.nft_id,
        tokenA: data.tokenX as TokenSymbol,
        tokenB: data.tokenY as TokenSymbol,
        tokenA_address: data.tokenX_address,
        tokenB_address: data.tokenY_address,
        chainId: chainId,
        feeTier: data.fee,
        liquidityValue: data.liquidity,
        price: poolInfo?.latestDealPrice ?? 0,
    };

    const favInfo = {
        chainId: data.nft_id ?? -1,
        address: data.address ?? '',
    };

    const { tokenInfoX, tokenInfoY, priceLeft, priceRight, setToggle, toggle } = useSwapPriceRange({
        tokenAddrA: entry.tokenA_address,
        tokenAddrB: entry.tokenB_address,
        chainId,
        maxPriceBPerA: data.priceLeft ?? 0,
        minPriceBPerA: data.priceRight ?? 0,
    });
    const onCopyAddress = () => {
        if (data.owner) {
            navigator.clipboard.writeText(data.owner);
        }
    };

    const OwnerBlock = [
        {
            width: '620px',
            title: 'Owner',
            value: data.owner,
        },
        {
            width: '95px',
            title: 'Min Tick',
            value: data.leftPt,
        },
        {
            width: '112px',
            title: 'Max Tick',
            value: data.rightPt,
        },
        {
            width: '180px',
            title: 'Liquidity Amount',
            value: liquidityDetailInfo.liquidityAmount,
        },
    ];

    return isMobile ? (
        <VStack w="100%" alignItems="left" mt="60px !important" spacing="20px" {...rest}>
            <HStack w="100%" mt="2px !important">
                <Card w="100%" h="62px">
                    <Flex
                        w="100%"
                        h="100%"
                        alignItems="center"
                        bg={colorTheme('linear-gradient(263.62deg, #F2F4FF 27.72%, rgba(242, 244, 255, 0) 72.47%)', '#232433')}
                        px="18px"
                    >
                        <TokenIcons
                            tokenAddrA={entry.tokenA_address as string}
                            tokenAddrB={entry.tokenB_address as string}
                            initialToggle={false}
                            chainId={entry.chainId}
                            showChainIcon={true}
                            size="sm"
                        />

                        <FeeRate ml="10px" tokenA={entry.tokenA} tokenB={entry.tokenB} feeTier={entry.feeTier} initialToggle={false} />
                        <Flex
                            alignItems="center"
                            bgColor={colorTheme('#ffffff', '#313545')}
                            color={colorTheme('#4D4A6F', '#9BA2BB')}
                            borderRadius="6px"
                            ml="auto"
                            mr="8px"
                        >
                            <Flex h="27px" alignItems="center" px="8px">
                                <Text className={i_text_copy}>ID: {entry.nft_id}</Text>
                            </Flex>
                        </Flex>

                        <FavButton
                            w="20px"
                            h="20px"
                            variant="base"
                            isFav={favInfoKey(favInfo) in favorite.favLiquidity}
                            setIsFav={(isAdd: boolean) =>
                                dispatch.favorite.modFavLiquidity({
                                    isAdd,
                                    favInfo,
                                })
                            }
                            cursor="pointer"
                            boxSize="15px"
                            m="0!important"
                        />
                    </Flex>
                </Card>
            </HStack>

            <Stack w="100%" spacing="12px">
                <Card w="100%" h="100%" variant="base2" position="relative">
                    <Stack spacing="16px" p="20px">
                        <Text className={i_text_copy} color="gray.500" mt="5px">
                            Liquidity
                        </Text>
                        <Stack w="100%" spacing="6px">
                            <HStack bg={colorTheme('#F9F9FB', '#2A2B35')} px="12px" py="6px">
                                <Text className={i_text_copy_bold} fontSize="16px">
                                    {isNumeric(liquidityDetailInfo.worthAmountX + liquidityDetailInfo.worthAmountY) ? (
                                        <>
                                            <Text className={i_text_d} color={colorTheme('gray.600', '#8B8E9B')} as="span" mr="5px">
                                                ~
                                            </Text>
                                            {formatNumber(liquidityDetailInfo.worthAmountX + liquidityDetailInfo.worthAmountY, 2, 2)}
                                        </>
                                    ) : (
                                        '-'
                                    )}
                                </Text>
                                <Text className={i_text_d} color="#8B8E9B" ml="auto !important">
                                    USD
                                </Text>
                            </HStack>

                            <Stack spacing="6px">
                                <Flex
                                    alignItems="center"
                                    bgColor={colorTheme('gray.0', '#232433')}
                                    bg={colorTheme('#F9F9FB', '#2A2B35')}
                                    borderRadius="6px"
                                    px="12px"
                                    py="6px"
                                >
                                    <Image
                                        boxSize="21px"
                                        src={(toggle ? tokenInfoY : tokenInfoX)?.icon ?? '/assets/tokens/default.svg'}
                                    ></Image>
                                    <Text className={i_text_Bold} color={colorTheme('#383A43', '#ADB5D6')} ml="12px">
                                        {formatNumber(liquidityDetailInfo.amountXDecimal, 2, 4, 2)}
                                        <Text className={i_text_copy} as="span" color="#8B8E9B" ml="6px">
                                            {liquidityDetailInfo.tokenX?.symbol}
                                        </Text>
                                    </Text>
                                    <Flex
                                        ml="auto"
                                        px="6px"
                                        py="3px"
                                        borderRadius="3px"
                                        bgColor={colorTheme('secondary.100', '#322E60')}
                                        color={colorTheme('#5E1597', '#8C70FF')}
                                    >
                                        {isNumeric(liquidityDetailInfo.worthAmountXRate) ? (
                                            <Text className={i_text_copy}>
                                                {formatNumber(liquidityDetailInfo.worthAmountXRate * 100, 2, 2)} %
                                            </Text>
                                        ) : (
                                            '-'
                                        )}
                                    </Flex>
                                </Flex>
                                <Flex
                                    alignItems="center"
                                    bgColor={colorTheme('gray.0', '#232433')}
                                    bg={colorTheme('#F9F9FB', '#2A2B35')}
                                    borderRadius="6px"
                                    px="12px"
                                    py="6px"
                                >
                                    <Image
                                        boxSize="21px"
                                        src={(toggle ? tokenInfoX : tokenInfoY)?.icon ?? '/assets/tokens/default.svg'}
                                    ></Image>
                                    <Text className={i_text_Bold} color={colorTheme('#383A43', '#ADB5D6')} ml="12px">
                                        {formatNumber(liquidityDetailInfo.amountYDecimal, 2, 4, 2)}
                                        <Text className={i_text_copy} as="span" color="#8B8E9B" ml="6px">
                                            {liquidityDetailInfo.tokenY?.symbol}
                                        </Text>
                                    </Text>
                                    <Flex
                                        ml="auto"
                                        px="6px"
                                        py="3px"
                                        borderRadius="3px"
                                        bgColor={colorTheme('secondary.100', '#322E60')}
                                        color={colorTheme('#5E1597', '#8C70FF')}
                                    >
                                        {isNumeric(liquidityDetailInfo.worthAmountXRate) ? (
                                            <Text className={i_text_copy}>
                                                {formatNumber(100 - liquidityDetailInfo.worthAmountXRate * 100, 2, 2)} %
                                            </Text>
                                        ) : (
                                            '-'
                                        )}
                                    </Flex>
                                </Flex>
                            </Stack>
                        </Stack>
                        <Divider></Divider>

                        <Stack w="100%" direction="column" spacing="11px">
                            <Text className={i_text_copy} color="gray.500">
                                Price Range
                            </Text>
                            <Flex alignItems="center" justifyContent="center">
                                <Image boxSize="21px" src={tokenInfoX?.icon ?? '/assets/tokens/default.svg'}></Image>
                                <Text className={i_text_d} fontWeight="600" ml="9px">
                                    {tokenInfoX?.symbol}
                                </Text>
                                <Image w="80px" src={'/assets/Liquidity/grayRightArrow.svg'} mx="12px"></Image>
                                <Image boxSize="21px" src={tokenInfoY?.icon ?? '/assets/tokens/default.svg'}></Image>
                                <Text className={i_text_d} fontWeight="600" ml="9px">
                                    {tokenInfoY?.symbol}
                                </Text>
                                <CustomButton
                                    w="32px"
                                    h="26px"
                                    bgColor={colorTheme('#EBEAEC', '#3D4256')}
                                    color={colorTheme('#52466A', '#EBEAEC')}
                                    ml="20px"
                                    fontSize="14px"
                                    onClick={setToggle}
                                    bgImage={colorTheme(
                                        process.env.PUBLIC_URL + '/assets/Liquidity/whiteTypeChange.svg',
                                        process.env.PUBLIC_URL + '/assets/Liquidity/darkTypeChange.svg'
                                    )}
                                    bgSize="100%"
                                ></CustomButton>{' '}
                            </Flex>
                            <HStack spacing="16px">
                                <Stack
                                    w="50%"
                                    bgColor={colorTheme('gray.0', '#2A2B35')}
                                    borderRadius="6px"
                                    spacing="6px"
                                    py="9px"
                                    pl="12px"
                                >
                                    <Text className={i_text_copy} color="gray.400">
                                        MIN
                                    </Text>
                                    <Text className={i_text_Bold} fontSize="20px">
                                        {formatNumber(priceLeft, 4, 4, 2)}
                                    </Text>
                                    <Text className={i_text_d} color="#424B78">
                                        {tokenInfoY?.symbol}
                                    </Text>
                                    <Text className={i_text_d} color="#5371FF">
                                        = 1 {tokenInfoX?.symbol}
                                    </Text>
                                </Stack>
                                <Stack
                                    w="50%"
                                    bgColor={colorTheme('gray.0', '#2A2B35')}
                                    borderRadius="6px"
                                    spacing="6px"
                                    py="9px"
                                    pl="12px"
                                >
                                    <Text className={i_text_copy} color="gray.400">
                                        MAX
                                    </Text>
                                    <Text className={i_text_Bold} fontSize="20px">
                                        {formatNumber(priceRight, 4, 4, 2)}
                                    </Text>
                                    <Text className={i_text_d} color="#424B78">
                                        {tokenInfoY?.symbol}
                                    </Text>
                                    <Text className={i_text_d} color="#5371FF">
                                        = 1 {tokenInfoX?.symbol}
                                    </Text>
                                </Stack>
                            </HStack>
                        </Stack>
                    </Stack>
                </Card>
                <Card w="100%" px="20px" py="16px">
                    <Stack spacing="12px">
                        <Text className={i_text_copy} color="gray.500">
                            Current Price
                        </Text>
                        <HStack className={i_text_d} mt="10px" fontSize="14px">
                            <Image boxSize="12px" src={tokenInfoX?.icon ?? '/assets/tokens/default.svg'}></Image>
                            <Text className={i_text_Bold} color={colorTheme('#383A43', '#FFFFFF')}>
                                1
                                <Text as="span" className={i_text_copy} color="#989BA8" ml="6px">
                                    {entry.tokenA}
                                </Text>
                            </Text>

                            <Text className={i_text_copy} color="#989BA8">
                                =
                            </Text>
                            <Image boxSize="12px" src={tokenInfoY?.icon ?? '/assets/tokens/default.svg'}></Image>
                            <Text className={i_text_Bold} color={colorTheme('#383A43', '#FFFFFF')}>
                                {formatNumber(toggle ? 1 / (entry.price ?? 1) : entry.price, 4, 4, 2)}
                                <Text as="span" className={i_text_copy} color="#989BA8" ml="6px">
                                    {entry.tokenB}
                                </Text>
                            </Text>
                        </HStack>
                        <Divider></Divider>
                        <Stack w="100%">
                            <Text className={i_text_copy} color="gray.500">
                                Fees Gained
                            </Text>
                            <HStack w="100%" borderRadius="6px" bg={colorTheme('#F9F9FB', '#2A2B35')} py="6px" px="18px">
                                <Text className={i_text_d} fontWeight="600" mt="3px">
                                    {isNumeric(liquidityDetailInfo.worthFeeX + liquidityDetailInfo.worthFeeX)
                                        ? formatNumber(liquidityDetailInfo.worthFeeX + liquidityDetailInfo.worthFeeY, 2, 7)
                                        : '-'}
                                </Text>
                                <Text className={i_text_d} color="#8B8E9B" ml="auto !important">
                                    USD
                                </Text>
                            </HStack>

                            <HStack
                                className={i_text_d}
                                w="100%"
                                borderRadius="6px"
                                bg={colorTheme('#F9F9FB', '#2A2B35')}
                                py="6px"
                                px="18px"
                            >
                                <Image
                                    boxSize="12px"
                                    src={(toggle ? tokenInfoY : tokenInfoX)?.icon ?? '/assets/tokens/default.svg'}
                                ></Image>
                                <Text>{liquidityDetailInfo.feeXDecimal ? formatNumber(liquidityDetailInfo.feeXDecimal, 2, 7) : '-'} </Text>

                                <Text color="#8B8E9B" ml="auto !important">
                                    {' '}
                                    {entry.tokenA}{' '}
                                </Text>
                            </HStack>
                            <HStack
                                className={i_text_d}
                                w="100%"
                                borderRadius="6px"
                                bg={colorTheme('#F9F9FB', '#2A2B35')}
                                py="6px"
                                px="18px"
                            >
                                <Image
                                    boxSize="12px"
                                    src={(toggle ? tokenInfoX : tokenInfoY)?.icon ?? '/assets/tokens/default.svg'}
                                ></Image>
                                <Text>{liquidityDetailInfo.feeYDecimal ? formatNumber(liquidityDetailInfo.feeYDecimal, 2, 7) : '-'}</Text>

                                <Text color="#8B8E9B" ml="auto !important">
                                    {' '}
                                    {entry.tokenB}{' '}
                                </Text>
                            </HStack>
                        </Stack>
                    </Stack>
                </Card>
            </Stack>

            <Card w="100%" px="20px" py="10px">
                <Stack h="100%" alignItems="center">
                    <Flex w="100%" direction="column">
                        <Text className={i_text_copy} color="gray">
                            {'Owner'}
                        </Text>
                        <Flex alignItems="center">
                            <Text className={i_text_d} color={colorTheme('#424B78', '#ABB2D4')} mt="5px">
                                {data.owner && truncateWalletAddress(data.owner, 6, 3)}
                            </Text>

                            <Image
                                boxSize="12px"
                                src={colorTheme('/assets/Liquidity/copy.svg', '/assets/Liquidity/darkCopy.svg')}
                                ml="8px"
                                mt="5px"
                                cursor="pointer"
                                onClick={onCopyAddress}
                            ></Image>
                        </Flex>
                    </Flex>
                    <Divider></Divider>
                    <HStack w="100%" spacing="22px">
                        <Flex direction="column">
                            <Text className={i_text_copy} color="gray">
                                {'Min Tick'}
                            </Text>

                            <Flex alignItems="center">
                                <Text className={i_text_Bold} fontSize="12px" mt="5px">
                                    {data.leftPt}
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex direction="column">
                            <Text className={i_text_copy} color="gray">
                                {'Max Tick'}
                            </Text>

                            <Flex alignItems="center">
                                <Text className={i_text_Bold} fontSize="12px" mt="5px">
                                    {data.rightPt}
                                </Text>
                            </Flex>
                        </Flex>
                        <Flex direction="column">
                            <Text className={i_text_copy} color="gray">
                                {'Liquidity Amount'}
                            </Text>

                            <Flex alignItems="center">
                                <Text className={i_text_Bold} fontSize="12px" mt="5px">
                                    {liquidityDetailInfo.liquidityAmount}
                                </Text>
                            </Flex>
                        </Flex>
                    </HStack>
                </Stack>
            </Card>
        </VStack>
    ) : (
        <VStack w="100%" alignItems="left" mt="60px !important" spacing="20px" {...rest}>
            <HStack w="100%" mt="2px !important">
                <Card w="100%" h="62px">
                    <Flex
                        w="100%"
                        h="100%"
                        alignItems="center"
                        bg={colorTheme('linear-gradient(263.62deg, #F2F4FF 27.72%, rgba(242, 244, 255, 0) 72.47%)', '#232433')}
                        pl="26px"
                    >
                        <TokenIcons
                            tokenAddrA={entry.tokenA_address as string}
                            tokenAddrB={entry.tokenB_address as string}
                            initialToggle={false}
                            chainId={entry.chainId}
                            showChainIcon={true}
                            size="lg"
                        />

                        <FeeRate
                            ml="10px"
                            tokenA={entry.tokenA}
                            tokenB={entry.tokenB}
                            feeTier={entry.feeTier}
                            initialToggle={false}
                            size="lg"
                        />
                        {/* <InRange ml="44px"></InRange> */}
                        {/* <SecondTag ml="15px"></SecondTag> */}
                        <Flex alignItems="center" bgColor={colorTheme('#ffffff', '#34364B')} borderRadius="6px" ml="auto" mr="22px">
                            <Flex h="27px" alignItems="center" px="12px">
                                <Text className={i_text_copy}>ID: {entry.nft_id}</Text>
                            </Flex>
                        </Flex>
                        <Flex
                            w="38px"
                            h="27px"
                            mr="27px"
                            bgColor={colorTheme('#ffffff', '#34364B')}
                            borderRadius="6px"
                            alignItems="center"
                            justifyContent="center"
                        >
                            <FavButton
                                w="20px"
                                h="20px"
                                variant="base"
                                isFav={favInfoKey(favInfo) in favorite.favLiquidity}
                                setIsFav={(isAdd: boolean) =>
                                    dispatch.favorite.modFavLiquidity({
                                        isAdd,
                                        favInfo,
                                    })
                                }
                                cursor="pointer"
                                boxSize="15px"
                                m="0!important"
                            />
                        </Flex>
                    </Flex>
                </Card>
            </HStack>

            <Flex w="1150px" h="265px">
                <Card w="858px" h="100%" position="relative">
                    <Flex py="20px">
                        <Flex w="50%" direction="column" pl="47px">
                            <Text className={i_text_copy} color="gray.500" mt="5px">
                                Liquidity
                            </Text>
                            <Text className={i_h2} mt="5px">
                                {isNumeric(liquidityDetailInfo.worthAmountX + liquidityDetailInfo.worthAmountY) ? (
                                    <>
                                        <Text className={i_text_d} color="gray.600" as="span" mr="5px">
                                            ~
                                        </Text>
                                        {formatNumber(liquidityDetailInfo.worthAmountX + liquidityDetailInfo.worthAmountY, 2, 2)}
                                        <Text className={i_text_d} color="gray.600" as="span" ml="5px">
                                            USD
                                        </Text>
                                    </>
                                ) : (
                                    '-'
                                )}
                            </Text>
                            <Flex
                                w="350px"
                                h="62px"
                                mt="24px"
                                alignItems="center"
                                bgColor={colorTheme('gray.0', '#232433')}
                                borderRadius="6px"
                            >
                                <Image
                                    boxSize="21px"
                                    ml="11px"
                                    src={(toggle ? tokenInfoY : tokenInfoX)?.icon ?? '/assets/tokens/default.svg'}
                                ></Image>
                                <Text className={i_h4} fontFamily="Montserrat" ml="12px">
                                    {formatNumber(liquidityDetailInfo.amountXDecimal, 2, 4, 2)} {liquidityDetailInfo.tokenX?.symbol}
                                </Text>
                                <Flex
                                    ml="auto"
                                    mr="12px"
                                    px="6px"
                                    py="3px"
                                    borderRadius="3px"
                                    bgColor={colorTheme('secondary.100', '#303252')}
                                >
                                    {isNumeric(liquidityDetailInfo.worthAmountXRate) ? (
                                        <Text className={i_text_copy}>
                                            {formatNumber(liquidityDetailInfo.worthAmountXRate * 100, 2, 2)} %
                                        </Text>
                                    ) : (
                                        '-'
                                    )}
                                </Flex>
                            </Flex>
                            <Flex
                                w="350px"
                                h="62px"
                                alignItems="center"
                                bgColor={colorTheme('gray.0', '#232433')}
                                borderRadius="6px"
                                mt="11px"
                            >
                                <Image
                                    boxSize="21px"
                                    ml="11px"
                                    src={(toggle ? tokenInfoX : tokenInfoY)?.icon ?? '/assets/tokens/default.svg'}
                                ></Image>
                                <Text className={i_h4} fontFamily="Montserrat" ml="12px">
                                    {formatNumber(liquidityDetailInfo.amountYDecimal, 2, 4, 2)} {liquidityDetailInfo.tokenY?.symbol}
                                </Text>
                                <Flex
                                    ml="auto"
                                    mr="12px"
                                    px="6px"
                                    py="3px"
                                    borderRadius="3px"
                                    bgColor={colorTheme('secondary.100', '#303252')}
                                >
                                    {isNumeric(liquidityDetailInfo.worthAmountXRate) ? (
                                        <Text className={i_text_copy}>
                                            {formatNumber(100 - liquidityDetailInfo.worthAmountXRate * 100, 2, 2)} %
                                        </Text>
                                    ) : (
                                        '-'
                                    )}
                                </Flex>
                            </Flex>
                        </Flex>
                        <Divider h="210px" orientation="vertical" mt="12px"></Divider>

                        <Flex w="50%" direction="column" pl="47px">
                            <Text className={i_text_copy} color="gray.500" mt="5px">
                                Price Range
                            </Text>
                            <Flex alignItems="center" mt="14px">
                                <Image boxSize="21px" src={tokenInfoX?.icon ?? '/assets/tokens/default.svg'}></Image>
                                <Text className={i_text_d} fontWeight="600" ml="9px">
                                    {tokenInfoX?.symbol}
                                </Text>
                                <Image w="100px" src={'/assets/Liquidity/rightArrow.svg'} mx="16px"></Image>
                                <Image boxSize="21px" src={tokenInfoY?.icon ?? '/assets/tokens/default.svg'}></Image>
                                <Text className={i_text_d} fontWeight="600" ml="9px">
                                    {tokenInfoY?.symbol}
                                </Text>
                                <CustomButton
                                    w="32px"
                                    h="26px"
                                    bgColor={colorTheme('#EBEAEC', '#3D4256')}
                                    color={colorTheme('#52466A', '#EBEAEC')}
                                    ml="20px"
                                    fontSize="14px"
                                    onClick={setToggle}
                                    bgImage={colorTheme(
                                        process.env.PUBLIC_URL + '/assets/Liquidity/whiteTypeChange.svg',
                                        process.env.PUBLIC_URL + '/assets/Liquidity/darkTypeChange.svg'
                                    )}
                                    bgSize="100%"
                                ></CustomButton>{' '}
                            </Flex>
                            <Flex mt="27px">
                                <Flex
                                    w="160px"
                                    h="135px"
                                    direction="column"
                                    alignItems="center"
                                    bgColor={colorTheme('gray.0', '#232433')}
                                    borderRadius="6px"
                                    px="13px"
                                >
                                    <Text className={i_text_copy} color="gray.400" mt="21px">
                                        MIN
                                    </Text>
                                    <Text className={i_text_d} mt="16px">
                                        {formatNumber(priceLeft, 4, 4, 2)}
                                        <Text as="span" color={'#8D909E'}>
                                            {' '}
                                            {tokenInfoY?.symbol}
                                        </Text>{' '}
                                    </Text>

                                    <Text className={i_text_copy} color="gray.400" mt="12px">
                                        = 1 {tokenInfoX?.symbol}
                                    </Text>
                                </Flex>
                                <Flex
                                    w="160px"
                                    h="135px"
                                    direction="column"
                                    alignItems="center"
                                    bgColor={colorTheme('gray.0', '#232433')}
                                    borderRadius="6px"
                                    ml="12px"
                                    px="13px"
                                >
                                    <Text className={i_text_copy} color="gray.400" mt="21px">
                                        MAX
                                    </Text>
                                    <Text className={i_text_d} mt="16px">
                                        {formatNumber(priceRight, 4, 4, 2)}
                                        <Text as="span" color={'#8D909E'}>
                                            {' '}
                                            {tokenInfoY?.symbol}
                                        </Text>{' '}
                                    </Text>
                                    <Text className={i_text_copy} color="gray.400" mt="12px">
                                        = 1 {tokenInfoX?.symbol}
                                    </Text>
                                </Flex>{' '}
                            </Flex>
                        </Flex>
                    </Flex>
                </Card>
                <Flex direction="column" ml="13px">
                    <Card w="282px" h="104px" pl="20px">
                        <Text className={i_text_copy} color="gray.500" mt="15px">
                            Current Price
                        </Text>
                        <Stack className={i_text_d} mt="10px" fontSize="14px" spacing="3px">
                            <HStack>
                                <Image boxSize="12px" src={tokenInfoX?.icon ?? '/assets/tokens/default.svg'}></Image>
                                <Text color="gray.500">1 {entry.tokenA} </Text>
                                <Text>=</Text>
                            </HStack>
                            <HStack>
                                <Image boxSize="12px" src={tokenInfoY?.icon ?? '/assets/tokens/default.svg'}></Image>
                                <Text>
                                    {formatNumber(toggle ? 1 / (entry.price ?? 1) : entry.price, 4, 4, 2)} {entry.tokenB}{' '}
                                </Text>
                            </HStack>
                        </Stack>
                    </Card>
                    <Card w="282px" h="154px" mt="12px" px="20px">
                        <Text className={i_text_copy} color="gray.500" mt="15px">
                            Fees Gained
                        </Text>
                        <Text className={i_text_d} fontWeight="600" mt="3px">
                            {isNumeric(liquidityDetailInfo.worthFeeX + liquidityDetailInfo.worthFeeX)
                                ? formatNumber(liquidityDetailInfo.worthFeeX + liquidityDetailInfo.worthFeeY, 2, 7)
                                : '-'}
                            <Text as="span" className={i_text_d} color="gray.600" ml="5px">
                                USD
                            </Text>
                        </Text>
                        <Divider mt="11px"></Divider>
                        <Flex className={i_text_d} alignItems="center" mt="12px">
                            <Image boxSize="12px" src={(toggle ? tokenInfoY : tokenInfoX)?.icon ?? '/assets/tokens/default.svg'}></Image>
                            <Text ml="6px"> {entry.tokenA} </Text>
                            <Text ml="auto">
                                {liquidityDetailInfo.feeXDecimal ? formatNumber(liquidityDetailInfo.feeXDecimal, 2, 7, 2) : '-'}
                            </Text>
                        </Flex>
                        <Flex className={i_text_d} alignItems="center" mt="12px">
                            <Image boxSize="12px" src={(toggle ? tokenInfoX : tokenInfoY)?.icon ?? '/assets/tokens/default.svg'}></Image>
                            <Text ml="6px"> {entry.tokenB} </Text>
                            <Text ml="auto">
                                {liquidityDetailInfo.feeYDecimal ? formatNumber(liquidityDetailInfo.feeYDecimal, 2, 7, 2) : '-'}
                            </Text>
                        </Flex>
                    </Card>
                </Flex>
            </Flex>

            <Card w="1150px" h="80px" px="40px" py="10px">
                <Flex h="100%" alignItems="center">
                    {OwnerBlock.map((item, index) => {
                        return (
                            <Flex w={item.width} direction="column" key={index}>
                                <Text className={i_text_copy} color="gray">
                                    {item.title}
                                </Text>

                                <Flex alignItems="center">
                                    <Text className={i_text_d} mt="5px">
                                        {item.value}
                                        {console.info(item.value, '  isnumber  ', isNumeric(item.value))}
                                    </Text>
                                    {item.title === 'Owner' && (
                                        <Image
                                            boxSize="12px"
                                            src={colorTheme('/assets/Liquidity/copy.svg', '/assets/Liquidity/darkCopy.svg')}
                                            ml="8px"
                                            mt="5px"
                                            cursor="pointer"
                                            onClick={onCopyAddress}
                                        ></Image>
                                    )}
                                </Flex>
                            </Flex>
                        );
                    })}
                </Flex>
            </Card>
        </VStack>
    );
};
