import React from 'react';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

const CopyrightInfo: React.FC = () => {
    const { t } = useTranslation();
    return (
        <Box
            mb="66px"
            fontSize="9px"
            alignSelf={{ base: 'start', sm: 'center' }}
            fontFamily="Lato"
            fontWeight="bold"
            mt={{ base: '12px !important', sm: '8px !important' }}
        >
            <svg xmlns="http://www.w3.org/2000/svg" width="170" height="10" viewBox="0 0 170 10">
                <text
                    id="_2023_SoSwap._All_rights_reserved."
                    data-name="@2023 SoSwap. All rights reserved."
                    className="copyright-text"
                    x="-0.501"
                    y="7.375"
                >
                    {t('copyright-info')}
                </text>
            </svg>
        </Box>
    );
};

export default CopyrightInfo;
