import _ from "lodash";
import { PoolContract } from "../net/iZUMi-endpoints/src/web3/types/iZiSwap/Pool";
import { splitRange } from "./collections";
import { parallelCollect } from "./parallel";

const LEFT_MOST_PT = -800_000;
const RIGHT_MOST_PT = 800_000;

export type LiqChartData = {
    liqDist: number[][];
    currentTickIdx: number;
    currentTick: number;
    leftTick: number;
    tickSpacing: number;
};

const LIQ_SNAPSHOT_FETCH_PARALLEL = 4;

export const fetchLiqChartData = async (poolContract: PoolContract, liquiditySnapshotOffsetTickSpace = 300): Promise<LiqChartData> => {
    const pointDelta = Number(await poolContract!.methods.pointDelta().call());
    const poolState = await poolContract!.methods.state().call();
    const currentPoint = Number(poolState.currentPoint);

    const currentPointRound = currentPoint - (currentPoint % pointDelta);
    const offset_ticks = liquiditySnapshotOffsetTickSpace * pointDelta;
    const right_tick = Math.min(currentPointRound + offset_ticks, RIGHT_MOST_PT - (RIGHT_MOST_PT % pointDelta));
    const left_tick = Math.max(currentPointRound - offset_ticks, LEFT_MOST_PT + (((LEFT_MOST_PT % pointDelta) + pointDelta) % pointDelta));
    console.log(splitRange({ left: left_tick, right: right_tick }, 8));
    const liquiditySnapshotList = await parallelCollect(
        ...splitRange({ left: left_tick, right: right_tick }, LIQ_SNAPSHOT_FETCH_PARALLEL).map((range) =>
            poolContract.methods.liquiditySnapshot(range.left, range.right).call()
        )
    );
    const snapshot = _.flatten(liquiditySnapshotList).map((tick) => Number(tick));
    return buildLiqChartData(snapshot, left_tick, right_tick, currentPoint, Number(poolState.liquidity));
};

export function buildLiqChartData(snapshot: number[], tickL: number, tickR: number, currentTick: number, liquidity: number): LiqChartData {
    const liquiditySnapshot = [] as number[];
    let sum = 0;
    const tickSpacing = Math.round((tickR - tickL) / snapshot.length);

    let delta = 0;
    for (let i = 0; i < snapshot.length; i++) {
        const l = snapshot[i];
        sum += l;
        liquiditySnapshot.push(sum);
        const point = tickL + i * tickSpacing;
        if (point <= currentTick && point + tickSpacing > currentTick) {
            delta = liquidity - sum;
        }
    }
    for (let i = 0; i < liquiditySnapshot.length; i++) {
        liquiditySnapshot[i] += delta;
    }
    const currentTickIndexOrigin = Math.round((currentTick - tickL) / tickSpacing);

    const leftIdx = Math.max(0, currentTickIndexOrigin - 200);
    const rightIdx = Math.min(leftIdx + 400, liquiditySnapshot.length);
    const currentTickIndex = currentTickIndexOrigin - leftIdx;
    const liquiditySnapshotCut = [] as number[];
    for (let i = leftIdx; i < rightIdx; i++) {
        liquiditySnapshotCut.push(liquiditySnapshot[i]);
    }

    return {
        liqDist: liquiditySnapshotCut.map((r, idx) => [idx, r]),
        currentTickIdx: currentTickIndex,
        currentTick: currentTick,
        leftTick: tickL + leftIdx * tickSpacing,
        tickSpacing: tickSpacing,
    };
}
