import { ChainId } from '../types/mod';
import { getScanUrl } from './chains';
export const links = {
    docs: 'https://docs.izumi.finance',
    github: 'https://github.com/RaiFinance',
    telegram: 'https://t.me/RaiFinance',
    twitter: 'https://twitter.com/RaiFinance',
    medium: 'https://medium.com/rai-finance',
    discord: 'https://discord.gg/vUFpSwxTzz',
    reddit: 'https://www.reddit.com/r/izumiFinance/',
    defipulse: 'https://www.defipulse.com',
    research: 'https://docs.izumi.finance/research',
    iZiToken: 'https://etherscan.io/address/0x9ad37205d608b8b219e6a2573f922094cec5c200#readContract',
    mediaKit: 'https://drive.google.com/drive/folders/1XLvJbB5y6DWkl7O8qPXe1DJqSsaXVl5F?usp=sharing',
    liquidBoxDoc: 'https://docs.izumi.finance/product/liquidbox/why-do-protocols-need-liquidbox-to-gain-liquidity',
    iZiBuyLink:
        'https://app.uniswap.org/#/swap?outputCurrency=0x9ad37205d608B8b219e6a2573f922094CEc5c200&inputCurrency=ETH&exactAmount=1&exactField=input',
    disclaimer: 'https://docs.izumi.finance/disclaimer',
    alphaLink: 'https://alpha.izumi.finance/',
    mainLink: 'https://izumi.finance/',
    zksyncLink: 'https://zksync.izumi.finance/',
    mantleLink: 'https://mantle.izumi.finance/',
    lineaLink: 'https://linea.izumi.finance/',
    baseLink: 'https://base.izumi.finance/',
};

export const getNftUrl = (chainId: ChainId, contractAddress: string, nftId: string): string => {
    return getScanUrl(chainId) + 'token/' + contractAddress + '?a=' + nftId;
};
