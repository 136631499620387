import { BoxProps, VStack, Text, useColorMode, Center, Image } from "@chakra-ui/react";
import { useSetState } from "ahooks";
import Card from "../../../components/Card/Card";
import { i_text_copy } from "../../../style";
import { getColorThemeSelector } from "../../../utils/funcs";
import placeholder from "../../../assets/placeholder.png";
import { useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import { useChain } from "../../../hooks/useChain";
import { useEffect, useState } from "react";
import { LiquidityList } from "./LiquidityList";
import { getIziSwapLiquidityRecord, ResponseIziSwapLiquidityRecord } from "../../../net/iZUMi-endpoints/src/restful/izumiSwapLiquidity";
import { favInfoKey } from "../../../state/models/favorite/favorite";
import { MultiPageData, MultiPageSetting } from "../../../net/iZUMi-endpoints/src/restful/types/multiPage";

type FavoriteBlockProps = any & BoxProps;

export const LiquidityFavoriteBlock: React.FC<FavoriteBlockProps> = (props) => {
    const { ...rest } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { chainId } = useChain();

    const [setting, setSetting] = useSetState<MultiPageSetting>({
        page: 1,
        pageSize: 10,
    });
    const [liquidityData, setLiquidityData] = useSetState<MultiPageData<ResponseIziSwapLiquidityRecord>>({
        data: [],
        totalPage: 1,
        loading: false,
    });

    const {
        favorite: { favLiquidity },
    } = useSelector((state: RootState) => state);

    useEffect(() => {
        const favLiquidityKeys = Object.keys(favLiquidity);
        if (!chainId) {
            return;
        }
        if (favLiquidityKeys?.length <= 0) {
            setLiquidityData({ data: [], totalPage: 0 });
            return;
        }

        // TODO limit of fav in search
        const nft_id_list = favLiquidityKeys.map((k) => favLiquidity[k].chainId).join(",");
        getIziSwapLiquidityRecord({
            page: setting.page,
            page_size: setting.pageSize,
            chain_id: chainId,
            order_by: "-nft_id",
            nft_id_list,
        }).then((r) => {
            // TODO loading status
            const liquidityList = r.data.is_success ? r.data.data : [];
            const totalPage = r.data.is_success ? Math.ceil(r.data.total / setting.pageSize) : 1;
            const validLiquidityList = liquidityList.filter((l) => favInfoKey({ chainId: l.nft_id, address: l.address }) in favLiquidity);

            setLiquidityData({ data: validLiquidityList, totalPage });
        });
    }, [chainId, favLiquidity, setting]);

    return (
        <VStack
            w={{ base: "100%", sm: "1150px" }}
            alignItems="left"
            mt={{ base: "20px !important", sm: "60px !important" }}
            spacing="20px"
            {...rest}
        >
            <Text className={i_text_copy} fontSize="14px" color={colorTheme("gray.500", "gray.0")}>
                Your Favorite
            </Text>

            {liquidityData.data.length > 0 ? (
                <LiquidityList
                    setting={setting}
                    pageData={liquidityData}
                    setCurrentPage={(page: number) => setSetting({ page })}
                    setPageSize={(pageSize: number) => setSetting({ pageSize })}
                    showSearch={false}
                />
            ) : (
                <Card variant="base" w="100%" alignItems="left" p="30px 20px 30px 20px" {...rest}>
                    <Center>
                        <VStack>
                            <Image
                                w="150px"
                                h="120px"
                                src={process.env.PUBLIC_URL + colorTheme("/assets/lightNotConnect.png", "/assets/darkNotConnect.png")}
                                fallbackSrc={placeholder}
                            />
                            <Text className={i_text_copy} fontSize="13px" color={colorTheme("gray.400", "gray.400")}>
                                Watched liquidity will be shown here.
                            </Text>
                        </VStack>
                    </Center>
                </Card>
            )}
        </VStack>
    );
};
