import { Flex, BoxProps, useColorMode, Image, Text, HStack, Divider } from '@chakra-ui/react';
import React from 'react';
import styled from 'styled-components';
import { getColorThemeSelector } from '../../utils/funcs';
import NetworkSelect from '../Select/NetworkSelect/NetworkSelect';
import * as globalStyle from '../../style';
import { Search } from '../Search/Search';
import useIsMobile from '../../hooks/useIsMobile';
import { i_text_d } from '../../iZUMi-UI-toolkit/src/style';

const SoswapIcon = styled.div`
    transition: transform 0.3s ease;
    :hover {
        transform: rotate(-5deg);
    }
    @media (max-width: 768px) {
        img {
            width: 4.5rem;
        }
        .mobile {
            display: inline-block;
        }
        .screen {
            display: none;
        }
    } ;
`;

const Header: React.FC<BoxProps> = (props) => {
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const logoTextColor = colorTheme('#2D2648', '#FFFFFF');
    const isMobile = useIsMobile();

    return isMobile ? (
        <Flex direction="column" alignItems="start">
            <Flex
                alignItems="start"
                bg={colorTheme('#f9f9f9', '#1C1D24')}
                flexShrink={0}
                flexGrow={0}
                px="18px"
                {...props}
                zIndex="3"
                w="100%"
                direction="column"
            >
                <Flex h={{ base: '54px', sm: '140px' }} position="relative" alignItems="center" justifyContent="center" mt="10px">
                    {/* <Image
                        src={process.env.PUBLIC_URL + '/assets/sidebar/logo.svg'}
                        width="25px"
                        height="25px"
                        position={{ base: 'static', sm: 'absolute' }}
                        left="35px"
                        top="56px"
                    /> */}
                    <SoswapIcon>
                        <Image
                            position={{ base: 'static', sm: 'absolute' }}
                            left="35px"
                            top="56px"
                            className="mobile"
                            style={{ width: '40px', height: '40px' }}
                            src={process.env.PUBLIC_URL + '/assets/sidebar/logo.svg'}
                            alt="logo"
                        />
                    </SoswapIcon>
                    <Text
                        transitionProperty="color"
                        transitionDuration="0.2s"
                        transitionTimingFunction="ease"
                        color={logoTextColor}
                        fontSize="21px"
                        position={{ base: 'static', sm: 'absolute' }}
                        left="87px"
                        top="62px"
                        className={globalStyle.i_h3}
                        fontFamily="Montserrat-Bold"
                        ml={{ base: '10px', sm: '0px' }}
                    >
                        SoSwap
                    </Text>
                    <Text className={i_text_d} color="#4F95FF" ml="10px" fontSize="17px" mt="2px">
                        ANALYTICS
                    </Text>
                </Flex>

                <HStack spacing="22px" w="100%" justifyContent="space-between">
                    <Search />

                    <NetworkSelect />
                </HStack>
                {isMobile && <Divider mt="10px"></Divider>}
            </Flex>
        </Flex>
    ) : (
        <Flex direction={{ base: 'column', sm: 'row' }} alignItems="center">
            <Flex
                justifyContent="space-between"
                alignItems="center"
                bg={colorTheme('unset', '#1C1D24')}
                flexShrink={0}
                flexGrow={0}
                height={{ base: 'unset', sm: '54px' }}
                {...props}
                zIndex="3"
                w="100%"
                // h={{ base: '100px', sm: '140px' }}
                pr={isMobile ? '20px' : '80px'}
            >
                <Flex
                    flexShrink={0}
                    w={isMobile ? '150px' : '300px'}
                    h={{ base: '80px', sm: '140px' }}
                    position="relative"
                    alignItems="center"
                    justifyContent="center"
                >
                    {/* <Image
                        src={process.env.PUBLIC_URL + '/assets/sidebar/logo.svg'}
                        width="38px"
                        height="38px"
                        position={{ base: 'static', sm: 'absolute' }}
                        left="35px"
                        top="56px"
                    /> */}

                    <SoswapIcon>
                        <Image
                            position={{ base: 'static', sm: 'absolute' }}
                            left="25px"
                            top="50px"
                            style={{ width: '114px', height: '40px' }}
                            src={process.env.PUBLIC_URL + colorTheme('/assets/sidebar/logo-dark.png', '/assets/sidebar/logo-white.png')}
                            alt="logo"
                        />
                    </SoswapIcon>
                    <Text
                        transitionProperty="color"
                        transitionDuration="0.2s"
                        transitionTimingFunction="ease"
                        color={logoTextColor}
                        fontSize="21px"
                        position={{ base: 'static', sm: 'absolute' }}
                        left="147px"
                        top="55px"
                        className={globalStyle.i_h3}
                        fontFamily="Montserrat-Bold"
                        ml={{ base: '10px', sm: '0px' }}
                    >
                        SoSwap
                    </Text>

                    {!isMobile && (
                        <Text
                            transitionProperty="color"
                            transitionDuration="0.2s"
                            transitionTimingFunction="ease"
                            color={colorTheme('#001AFF', '#4F95FF')}
                            fontSize="18px"
                            position="absolute"
                            left="250px"
                            top="55px"
                            fontFamily="Montserrat"
                        >
                            ANALYTICS
                        </Text>
                    )}
                </Flex>

                <HStack spacing="50px" mt={isMobile ? '4px' : 'unset'}>
                    {!isMobile && <Search />}
                    <NetworkSelect />
                </HStack>
            </Flex>
            {isMobile && <Search />}
        </Flex>
    );
};

export default Header;
